import { call, put, select } from "redux-saga/effects";
import { PayloadAction } from "@reduxjs/toolkit";
import { AxiosResponse } from "axios";
import { applicationType } from "../../types/Applications/types";
import { declineApplicationType, declineResponseType } from "../../api/underwriter/types";
import { API } from "../../api";
import { sagaActions } from "../util/sagaActions";
import { errorHandlerSaga } from "../util/helpers/errorHandlerSaga";
import { processStateController } from "../util/helpers/processStateController";
import { cachedPhotos } from "../../store/indexedDB";
import { applicationsReducer, applicationsReducerSelectors } from "../../store/reducers/applications";

export function* declineApplicationSaga({
  payload,
  type: actionType,
}: PayloadAction<declineApplicationType>) {
  const process = processStateController(actionType);
  try {
    yield process.start();
    yield put(applicationsReducer.actions.setLoadingDecline(true));

    const application: applicationType = yield select(
      applicationsReducerSelectors.getCurrentApplication
    );

    const response: AxiosResponse<declineResponseType> = yield call(
      API.underwriter.declineApplication,
      payload,
    );

    if (response.status === 204) {
      cachedPhotos.deletePhoto(
        application?.document_photos.concat(application?.car_photos).map((item) => item?.id)
      );
    }

    yield put(sagaActions.underwriter.getApplications());
    yield put(applicationsReducer.actions.setSaveCurrentApplication(true));
  } catch (e: any) {
    yield call(errorHandlerSaga, {
      response: e,
      processType: sagaActions.underwriter.declineApplication.type,
    });
  } finally {
    yield process.stop();
  }
}
