import { createAction } from "@reduxjs/toolkit";
import {
  approveApplicationType,
  declineApplicationType,
  getDocumentType,
  underwriterBasisType,
  updateApplicationType,
  uploadApplicationDocumentType,
} from "../api/underwriter/types";
import { sendPatchFromScoringPayloadType } from "./underwriter/sendPatchFromScoringSaga";
import { sendPatchFromCreditworthinessSagaType } from "./underwriter/sendPatchFromCreditworthinessSaga";

export const underwriterActions = {
  declineApplication: createAction<declineApplicationType>(
    "underwriter/declineApplication"
  ),
  approveApplication: createAction<approveApplicationType>(
    "underwriter/approveApplication"
  ),
  approveApplicationSuperUnderwriter: createAction<approveApplicationType>(
    "superunderwriter/approveApplication"
  ),
  updateApplication: createAction<updateApplicationType>(
    "underwriter/updateApplication"
  ),
  updateApplicationPatch: createAction<updateApplicationType>(
    "underwriter/updateApplicationPatch"
  ),
  downloadFile: createAction<getDocumentType>("underwriter/downloadFile"),

  pingApplications: createAction<{
    delay?: number;
  }>("underwriter/pingApplications"),

  getUserSaga: createAction<{}>("underwriter/getUserSaga"),

  getApplications: createAction("/underwriter/getApplication"),
  getApplication: createAction<underwriterBasisType>(
    "/underwriter/getCurrentApplicationSaga"
  ),
  clearRedusersSaga: createAction("/underwriter/clearRedusersSaga"),

  uploadApplicationDocumentSaga: createAction<uploadApplicationDocumentType>(
    "underwriter/uploadApplicationDocumentSaga"
  ),
  sendPatchFromScoringSaga: createAction<sendPatchFromScoringPayloadType>(
    "underwriter/sendPatchFromScoringSaga"
  ),
  sendPatchFromCreditworthinessSaga:
    createAction<sendPatchFromCreditworthinessSagaType>(
      "underwriter/sendPatchFromCreditworthinessSaga"
    ),
  changeApplication: createAction(
    "underwriter/changeApplication"
  ),
  logout: createAction("underwriter/logout"),
};
